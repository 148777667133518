* {
    margin: 0;
}
html {
    font-size: 1vw;
}
body {
    min-height: 100vh;
    display: grid;
    place-items: center;
    /*background-color: #E7F5F3 !important;*/
    background-color: rgb(28, 3, 38) !important;
}
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh !important;
    width: 100vw !important;
}
.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
}
.container .main .form {
    flex: 1;
    width: 60%;
}
.container .main .button {
    width: 100%;
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    margin: 1.5rem 0 3rem 0;
    font-size: 1.3rem;
    font-weight: bold;
    border: 1px solid #FFFFFF;
}
.container .main .form .labelText {
    margin-top: 1rem;
    font-size: 1.1rem;
    margin-bottom: 2rem;
    color: #FFFFFF;

}
.container .main .form .textInput {
    font-size: 2rem;
}
.container .main h1 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
}
.link_button {
    width: 90%;
    background-color: #5C5C5C !important;
    padding: 1.5rem 1rem 1.5rem 1rem !important;
    font-size: 2rem;
    border-radius: 0.8rem !important;
    color: #FFFFFF;
    cursor: pointer;
}
.clg_logo {
    position: absolute;
    top:15%;
    width: 15rem;
    height: auto;
    object-fit: contain;
    margin: 0 auto;
    display: block;
}

.phoneInput {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.countryCode {
    flex: 0.3;
    font-size: 2rem !important;
    margin-right: 1rem;
}

.phoneNumber {
    flex: 1;
    font-size: 2rem !important;
}

.birthdaypicker {
    width: 100%;
}

.react-tel-input .form-control {
    height: 3rem !important;
    font-size: 1.5rem !important;
}

.react-calendar {
    position: fixed; /* Changed from absolute to fixed */
    top: 50%; /* Position at 50% from the top */
    left: 50%; /* Position at 50% from the left */
    transform: translate(-50%, -50%); /* Move the calendar back by 50% of its own width and height to center it */
    z-index: 1000; /* Ensure the calendar appears above other elements */
}

.react-date-picker__wrapper {
    width: 100% !important;
    height: 3rem !important;
    font-size: 1.5rem !important;
}

.react-date-picker__inputGroup__month {
    width: 2rem !important;
}

.react-date-picker__inputGroup__day {
    width: 2rem !important;
}

.react-date-picker__inputGroup__year {
    width: 4rem !important;
}

.birthdate-input {
    height: 3rem;
    font-size: 1.5rem !important;
    font-family: 'Roboto',sans-serif;
}

@media screen and (max-width: 600px) {

    .container {
        margin: 0 auto !important;
        height: 100vh !important;
        width: 100vw !important;
        background-color: rgb(28, 3, 38) !important;

    }
    .main {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #FFFFFF;
    }
    .container .main .form {
        position: absolute;
        top: 40%;
        flex: 1;
        width: 85% !important;
        color: #FFFFFF;
    }
    .container .main .button {
        width: 100%;
        padding-top: 2rem;
        padding-bottom: 2rem;
        margin: 3rem 0 6rem 0;
        font-size: 2.5rem;
        font-weight: bold;
        border: 1px solid #FFFFFF;
    }
    .container .main .form .labelText {
        margin-top: 2rem;
        font-size: 5rem;
        margin-bottom: 3.5rem;
    }
    .container .main .form .textInput {
        font-size: 4.5rem;
        margin-bottom: 3.5rem;
    }
    .container .main h1 {
        margin-top: 2.5rem;
        margin-bottom: 2.5rem;
        font-size: 5rem !important;
    }
    .link_button {
        width: 80%;
        padding-top: 5rem !important;
        padding-bottom: 5rem !important;
        margin-top: 2rem;
        font-size: 3.5rem !important;
        font-weight: bold;
        border-radius: 2rem;
        background-color: '#5C5C5C' !important;
        color: '#FFFFFF';
        cursor: pointer;
    }
    .clg_logo {
        position: absolute !important;
        top: 20% !important;
        width: 60vw !important;
        height: auto;
        object-fit: contain;
        margin: 0 auto;
        display: block;
        margin-bottom: 15rem;
    }
    .countryCode {
        flex: 0.3;
        font-size: 5rem !important;
        margin-right: 1rem;
    }
    
    .phoneNumber {
        flex: 1;
        font-size: 5rem !important;
    }

    .phoneInput .label {
        font-size: 5rem !important;
    }

    .react-tel-input .form-control {
        height: 15rem !important;
        width: 100% !important;
        font-size: 6rem !important;
    }

    .birthdate-input {
        height: 15rem;
        font-size: 6rem !important;
        font-family: 'Roboto',sans-serif;
    }

    .react-date-picker__wrapper {
        width: 100% !important;
        height: 15rem !important;
        font-size: 6rem !important;
    }

    .react-date-picker__inputGroup__month {
        width: 7rem !important;
    }
    
    .react-date-picker__inputGroup__day {
        width: 7rem !important;
    }
    
    .react-date-picker__inputGroup__year {
        width: 15rem !important;
    }

    .react-calendar {
        position: fixed; /* Changed from absolute to fixed */
        top: 50%; /* Position at 50% from the top */
        left: 50%; /* Position at 50% from the left */
        transform: translate(-50%, -50%); /* Move the calendar back by 50% of its own width and height to center it */
        z-index: 1000; /* Ensure the calendar appears above other elements */
        font-size: 2.5vh !important;
    }

    .swal2-popup {
        font-size: 3rem !important;
    }
    
    .swal2-styled {
        padding: 1rem 2rem 1rem 2rem !important;
        margin: 1rem 2rem 0rem 1rem !important;
    }

    .button {
        font-size: 6rem !important;
        border-radius: 2rem !important;
    }
}